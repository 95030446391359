import { useEffect } from 'react';

interface FieldObserverProps<T> {
  field: T;
  onChange: (value: T) => void;
}

function FieldObserver<T>(props: FieldObserverProps<T>): null {
  useEffect(() => {
    props.onChange(props.field);
  }, [props.field]);

  return null;
}

export default FieldObserver;
