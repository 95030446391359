import 'react-dates/initialize';
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
//@ts-ignore
import aphroditeInterface from 'react-with-styles-interface-aphrodite';
//@ts-ignore
import DefaultTheme from 'react-dates/lib/theme/DefaultTheme';

ThemedStyleSheet.registerInterface(aphroditeInterface);
ThemedStyleSheet.registerTheme({
  reactDates: {
    ...DefaultTheme.reactDates,
    color: {
      ...DefaultTheme.reactDates.color,
      core: {
        ...DefaultTheme.reactDates.color.core,
        primary: '#F2473F',
        primaryShade_1: '#E91910',
        primaryShade_2: '#B5140C',
        primaryShade_3: '#800E09',
        primaryShade_4: '#800E09',
        primary_dark: '#4B0805',
      },
      selected: {
        ...DefaultTheme.reactDates.color.selected,
        backgroundColor: '#F2473F',
        backgroundColor_active: '#F2473F',
        backgroundColor_hover: '#F2473F',
        borderColor: '#F2473F',
        borderColor_active: '#F2473F',
        borderColor_hover: '#F2473F',
      },
    },
  },
});
