import React from 'react';
import { Form, Formik } from 'formik';
import { BookPageFormProps, TimeSlotFormState } from '../@types';
import FormFooter from '../components/book/form/footer';
import { schema } from './schemas/time-slot';
import TimeSlotField from './fields/time-slot';
import TimeSlotInput from '../components/book/form/time-slot-input';
import getOrderTotal from '../runtime/get-order-total';

const TimeSlotForm: React.FC<BookPageFormProps> = ({
  id,
  active,
  first,
  last,
  formState,
  runtimeData,
  autoSubmitted,
  onNext,
  onBack,
}) => {
  const initialValues: TimeSlotFormState = {
    timeSlot: null,
  };

  const total = getOrderTotal(formState, runtimeData);

  const onSubmit = (values: TimeSlotFormState) => {
    onNext(id, values);
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <Form>
        <TimeSlotField
          name="timeSlot"
          appointmentDuration={total?.timeInMinutes}
          appointmentAddress={formState.address?.address?.formatted_address}
          shouldRenderPicker={active}
          element={TimeSlotInput}
        />
        <FormFooter
          autoSubmitted={autoSubmitted}
          active={active}
          first={first}
          last={last}
          onBack={onBack}
        />
      </Form>
    </Formik>
  );
};

export default TimeSlotForm;
