import * as Yup from 'yup';
import 'yup-phone';

const phone = Yup.string()
  .phone('US', true, 'Please specify valid phone number')
  .required('Phone number is required');

const email = Yup.string()
  .email('Please specify a valid e-mail')
  .required('E-mail is required');

export const schema = Yup.object().shape({
  email,
  phone,
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
});
