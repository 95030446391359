import React, { SyntheticEvent } from 'react';
import cntl from 'cntl';
import Spinner from '../../spinner';
import Alert from '../../alert';

interface FooterProps {
  active: boolean;
  first: boolean;
  last: boolean;
  loading?: boolean;
  error?: boolean;
  autoSubmitted: boolean;
  onBack: (evt: SyntheticEvent) => void;
}

const navContainerClassName = cntl`
  mt-12
  flex
  justify-between
  items-center
`;

const itemWrapperClassName = cntl`
  sm:mt-0
  sm:flex-shrink-0
`;

const backButtonClassName = cntl`
  cursor-pointer
  text-gray-500
  underline
`;

const nextButtonClassName = cntl`
  ml-8
  whitespace-nowrap
  inline-flex
  items-center
  justify-center
  px-6
  py-2
  border
  border-transparent
  rounded-md
  shadow-sm
  text-base
  font-medium
  focus:outline-none
  text-white
  bg-primary-600
  hover:bg-primary-700
`;

const spinnerClassName = cntl`
  -ml-1
  mr-3
  h-5
  w-5
  text-white
`;

const Footer: React.FC<FooterProps> = ({
  active,
  first,
  last,
  loading,
  error,
  autoSubmitted,
  onBack,
}) => {
  if (!active) {
    return null;
  }

  return (
    <div>
      {error ? (
        <div>
          <Alert title="Error">
            There was an error sending a verification sms to your phone number.
            Please re-enter your phone number or try reloading the page
          </Alert>
        </div>
      ) : null}
      <div className={navContainerClassName}>
        <div className={itemWrapperClassName}>
          {!first && (
            <div onClick={onBack} className={backButtonClassName}>
              Back
            </div>
          )}
        </div>
        <div className={itemWrapperClassName}>
          {!autoSubmitted && (
            <button
              disabled={loading}
              className={nextButtonClassName}
              type="submit"
            >
              {loading ? <Spinner className={spinnerClassName} /> : null}
              {!last ? 'Next' : 'Book'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Footer;
