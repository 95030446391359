import React from 'react';
import cntl from 'cntl';
import PhoneInput from 'react-phone-number-input/input';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { CustomPhoneInputProps } from '../../../@types';

const inputClassName = cntl`
  w-full
  focus:ring-primary-500
  focus:border-primary-500
  disabled:opacity-30
`;

const errorClassName = cntl`
  border-red-300
  text-red-900
  placeholder-red-300
  focus:ring-red-500
  focus:border-red-500
`;

const errorMessageClassName = cntl`
  mt-2
  text-sm
  text-red-600
`;

const iconWrapperClassName = cntl`
  absolute
  inset-y-0
  right-0
  pr-3
  flex
  items-center
  pointer-events-none
`;

const iconClassName = cntl`
  h-5
  w-5
  text-red-500
`;

const TextInput: React.FC<CustomPhoneInputProps> = ({
  error,
  value,
  onChange,
  placeholder,
  disabled,
}) => {
  return (
    <div>
      <div className="relative">
        <PhoneInput
          value={value}
          onChange={onChange}
          className={`${error ? errorClassName : ''} ${inputClassName}`}
          country="US"
          type="tel"
          placeholder={placeholder}
          disabled={disabled}
          autoComplete="off"
        />
        {error && (
          <div className={iconWrapperClassName}>
            <ExclamationCircleIcon
              className={iconClassName}
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error && <p className={errorMessageClassName}>{error}</p>}
    </div>
  );
};

export default TextInput;
