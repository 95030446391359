import {
  RuntimeQueryHookState,
  BookPageRuntimeQuery,
  ContentfulPackage,
  ContentfulCarType,
  BookFormState,
  ContentfulServicePricing,
} from '../@types';
import isRuntime from './is-runtime';

export const findCarTypeById = (
  state: BookFormState,
  runtimeData: RuntimeQueryHookState<BookPageRuntimeQuery>
): ContentfulCarType | undefined => {
  if (
    !isRuntime() ||
    typeof state.vehicle === 'undefined' ||
    state.vehicle.carTypeId === null
  ) {
    return undefined;
  }

  return runtimeData.data?.carTypes.items.find(
    c => c.sys.id === state.vehicle?.carTypeId?.value
  );
};

export const findPackageByCarTypeId = (
  state: BookFormState,
  runtimeData: RuntimeQueryHookState<BookPageRuntimeQuery>
): ContentfulPackage | undefined => {
  if (
    !isRuntime() ||
    typeof state.package === 'undefined' ||
    typeof state.vehicle === 'undefined' ||
    state.vehicle.carTypeId === null
  ) {
    return undefined;
  }

  const { package: selectedPackage } = state.package;

  return findCarTypeById(state, runtimeData)?.packages.items.find(
    p => p.sys.id === selectedPackage
  );
};

export const findAdditionalServices = (
  state: BookFormState,
  runtimeData: RuntimeQueryHookState<BookPageRuntimeQuery>
): ContentfulServicePricing[] => {
  if (typeof state.vehicle === 'undefined') {
    return [];
  }

  const carType = findCarTypeById(state, runtimeData);
  const selectedPackage = findPackageByCarTypeId(state, runtimeData);
  const servicePricing = carType?.servicePricing;

  const additionalServices: ContentfulServicePricing[] = [];

  servicePricing?.items.forEach(servicePricing => {
    let isInPackage = false;

    selectedPackage?.services.items.forEach(service => {
      if (service.sys.id === servicePricing.service.sys.id) {
        isInPackage = true;
      }
    });

    if (
      !isInPackage &&
      // Check if service category is
      // in available categories of the package
      selectedPackage?.categories.items.findIndex(
        c => c.sys.id === servicePricing.service.serviceCategory.sys.id
      ) !== -1
    ) {
      additionalServices.push(servicePricing);
    }
  });

  return additionalServices;
};

export const findSelectedServices = (
  state: BookFormState,
  runtimeData: RuntimeQueryHookState<BookPageRuntimeQuery>
): ContentfulServicePricing[] => {
  if (
    typeof state.services === 'undefined' ||
    state.services?.services.length === 0
  ) {
    return [];
  }

  const additionalServices: ContentfulServicePricing[] = findAdditionalServices(
    state,
    runtimeData
  );

  return additionalServices.filter(service =>
    state.services?.services.includes(service.sys.id)
  );
};
