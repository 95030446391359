import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import { SelectMenuValue, CustomDropdownProps } from '../../@types';

interface AdditionalProps {
  options: Array<SelectMenuValue>;
  label: string;
  loading?: boolean;
  element: React.ComponentType<CustomDropdownProps>;
}

const DropdownField: React.FC<
  AdditionalProps & FieldHookConfig<SelectMenuValue>
> = props => {
  const [, meta, helpers] = useField(props);
  const { value } = meta;
  const { setValue } = helpers;
  const error = meta.touched && meta.error ? meta.error : undefined;
  const Element = props.element;

  return (
    <Element
      options={props.options}
      error={error}
      label={props.label}
      loading={props.loading}
      selected={value}
      setSelected={selected => setValue(selected)}
    />
  );
};

export default DropdownField;
