import React from 'react';
import { Form, Formik } from 'formik';
import { BookPageFormProps, ServicesFormState } from '../@types';
import FormFooter from '../components/book/form/footer';
import { schema } from './schemas/services';
import { findAdditionalServices } from '../runtime/search';
import CheckGroupField from './fields/check-group';
import ServiceCheckGroup from '../components/book/form/service-check-group';

const ServicesForm: React.FC<BookPageFormProps> = ({
  id,
  active,
  first,
  last,
  autoSubmitted,
  formState,
  runtimeData,
  onBack,
  onNext,
}) => {
  const initialValues: ServicesFormState = {
    services: [],
  };

  const services = findAdditionalServices(formState, runtimeData);

  const onSubmit = (values: ServicesFormState) => {
    onNext(id, values);
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <Form>
        <CheckGroupField
          name="services"
          element={ServiceCheckGroup}
          items={services}
        />
        <FormFooter
          autoSubmitted={autoSubmitted}
          active={active}
          first={first}
          last={last}
          onBack={onBack}
        />
      </Form>
    </Formik>
  );
};

export default ServicesForm;
