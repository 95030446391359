import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import {
  BookPageFormProps,
  SelectMenuValue,
  VehicleFormState,
} from '../@types';
import FormFooter from '../components/book/form/footer';
import { schema } from './schemas/vehicle';
import FormRow from '../components/book/form/row';
import Dropdown from '../components/book/form/dropdown';
import ColorDropdown from '../components/book/form/color-dropdown';
import CarTypeDropdown from '../components/book/form/car-type-dropdown';
import DropdownField from './fields/dropdown';
import FieldObserver from './fields/field-observer';
import {
  useAllYears,
  useAllMakes,
  useAllModels,
} from '../runtime/hooks/useCarApi';
import {
  transformYears,
  transformMakes,
  transformModels,
  transformColors,
  transformCarTypes,
} from '../runtime/transform';

const VehicleForm: React.FC<BookPageFormProps> = ({
  id,
  active,
  first,
  last,
  runtimeData,
  autoSubmitted,
  onNext,
  onBack,
}) => {
  const initialValues: VehicleFormState = {
    year: null,
    make: null,
    model: null,
    color: null,
    carTypeId: null,
  };

  // Keep this state items to track changes on hooks
  const [year, setYear] = useState<null | string>(null);
  const [make, setMake] = useState<null | string>(null);

  const allYears = useAllYears();
  const allMakes = useAllMakes(year !== null, year || '');
  const allModels = useAllModels(make !== null, year || '', make || '');

  const onSubmit = (values: VehicleFormState) => {
    onNext(id, values);
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {props => (
        <Form>
          <FieldObserver
            field={props.values.year}
            onChange={(value: SelectMenuValue | null) =>
              setYear(value?.value || null)
            }
          />
          <FieldObserver
            field={props.values.make}
            onChange={(value: SelectMenuValue | null) =>
              setMake(value?.value || null)
            }
          />
          <FormRow>
            <DropdownField
              element={Dropdown}
              name="year"
              label="Select vehicle year"
              options={transformYears(allYears.data)}
              loading={allYears.loading}
            />
          </FormRow>
          {props.values.year && (
            <FormRow>
              <DropdownField
                element={Dropdown}
                name="make"
                label="Select vehicle make"
                options={transformMakes(allMakes.data)}
                loading={allMakes.loading}
              />
            </FormRow>
          )}
          {props.values.make && (
            <FormRow>
              <DropdownField
                element={Dropdown}
                name="model"
                label="Select vehicle model"
                options={transformModels(allModels.data)}
                loading={allModels.loading}
              />
            </FormRow>
          )}
          {props.values.model && (
            <FormRow>
              <DropdownField
                element={ColorDropdown}
                name="color"
                label="Select vehicle color"
                options={transformColors()}
              />
            </FormRow>
          )}
          {props.values.color && (
            <FormRow>
              <DropdownField
                element={CarTypeDropdown}
                name="carTypeId"
                label="Select vehicle type"
                options={transformCarTypes(runtimeData)}
              />
            </FormRow>
          )}
          <FormFooter
            autoSubmitted={autoSubmitted}
            active={active}
            first={first}
            last={last}
            onBack={onBack}
          />
        </Form>
      )}
    </Formik>
  );
};

export default VehicleForm;
