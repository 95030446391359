import React, { Fragment } from 'react';
import cntl from 'cntl';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { CustomDropdownProps } from '../../../@types';
import { classNames } from '../../../utils';

const labelClassName = cntl`
  block
  text-sm
  font-medium
`;

const containerClassName = cntl`
  mt-1
  relative
`;

const listBoxButtonBaseClassName = cntl`
  bg-white
  relative
  w-full
  border
  pl-3
  pr-10
  py-2
  text-left
  cursor-default
  focus:outline-none
  focus:ring-1
  sm:text-md
`;

const listBoxButtonClassName = cntl`
  border-gray-500
  focus:ring-primary-500
  focus:border-primary-500
`;

const listBoxButtonErrorClassName = cntl`
  border-red-500
  focus:ring-red-500
  focus:border-red-500
`;

const valueWrapperClassName = cntl`
  flex
  items-center
`;

const colorIconClassName = cntl`
  flex-shrink-0
  inline-block
  h-2
  w-2
  rounded-full
`;

const valueClassName = cntl`
  ml-3
  block
  truncate
`;

const dropdownIconWrapperClassName = cntl`
  absolute
  inset-y-0
  right-0
  flex
  items-center
  pr-2
  pointer-events-none
`;

const dropdownIconClassName = cntl`
  h-5
  w-5
  text-gray-400
`;

const optionContainerClassName = cntl`
  absolute
  z-10
  mt-1
  w-full
  bg-white
  shadow-lg
  max-h-60
  rounded-md
  py-1
  text-base
  ring-1
  ring-black
  ring-opacity-5
  overflow-auto
  focus:outline-none
  sm:text-sm
`;

const optionClassName = cntl`
  cursor-default
  select-none
  relative
  py-2
  pl-3
  pr-9
`;

const optionWrapperClassName = cntl`
  flex
  items-center
`;

const optionLabelClassName = cntl`
  ml-3
  block
  truncate
`;

const optionColorIconClassName = cntl`
  flex-shrink-0
  inline-block
  h-2
  w-2
  rounded-full
`;

const optionCheckIconWrapperClassName = cntl`
  absolute
  inset-y-0
  right-0
  flex
  items-center
  pr-4
`;

const optionCheckIconClassName = cntl`
  h-5
  w-5
`;

const getStyles = (backgroundColor = 'gray') => {
  return { backgroundColor };
};

const ColorDropdown: React.FC<CustomDropdownProps> = ({
  error,
  label,
  options,
  selected,
  setSelected,
}) => {
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label
            className={classNames(
              labelClassName,
              error ? 'text-red-600' : 'text-gray-700'
            )}
          >
            {error ?? label}
          </Listbox.Label>
          <div className={containerClassName}>
            <Listbox.Button
              className={classNames(
                listBoxButtonBaseClassName,
                error ? listBoxButtonErrorClassName : listBoxButtonClassName
              )}
            >
              <div className={valueWrapperClassName}>
                <span
                  aria-label={selected?.label}
                  className={colorIconClassName}
                  style={getStyles(selected?.value)}
                />
                <span
                  className={classNames(
                    valueClassName,
                    selected !== null ? 'text-black' : 'text-gray-500'
                  )}
                >
                  {selected !== null ? selected.label : label}
                </span>
              </div>
              <span className={dropdownIconWrapperClassName}>
                <SelectorIcon
                  className={dropdownIconClassName}
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options static className={optionContainerClassName}>
                {options.map(option => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-primary-600' : 'text-gray-900',
                        optionClassName
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className={optionWrapperClassName}>
                          <span
                            style={getStyles(option.value)}
                            className={optionColorIconClassName}
                            aria-hidden="true"
                          />
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              optionLabelClassName
                            )}
                          >
                            {option.label}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-primary-600',
                              optionCheckIconWrapperClassName
                            )}
                          >
                            <CheckIcon
                              className={optionCheckIconClassName}
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default ColorDropdown;
