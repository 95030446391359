import React from 'react';
import { Form, Formik } from 'formik';
import { BookPageFormProps } from '../@types';
import FormFooter from '../components/book/form/footer';
import { schema } from './schemas/your-info';
import { YourInfoFormState } from '../@types';
import FormRow from '../components/book/form/row';
import TextInput from '../components/book/form/text-input';
import PhoneInput from '../components/book/form/phone-input';
import TextField from './fields/text';
import PhoneField from './fields/phone';

const YourInfoForm: React.FC<BookPageFormProps> = ({
  id,
  active,
  first,
  last,
  autoSubmitted,
  onNext,
  onBack,
}) => {
  const initialValues: YourInfoFormState = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  };

  const onSubmit = (values: YourInfoFormState) => {
    onNext(id, values);
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <Form>
        <FormRow>
          <TextField
            name="firstName"
            type="text"
            element={TextInput}
            placeholder="Enter your first name"
          />
        </FormRow>
        <FormRow>
          <TextField
            name="lastName"
            type="text"
            element={TextInput}
            placeholder="Enter your last name"
          />
        </FormRow>
        <FormRow>
          <PhoneField
            name="phone"
            element={PhoneInput}
            placeholder="Enter your phone number"
          />
        </FormRow>
        <FormRow>
          <TextField
            name="email"
            type="email"
            element={TextInput}
            placeholder="Enter your e-mail"
          />
        </FormRow>
        <FormFooter
          autoSubmitted={autoSubmitted}
          active={active}
          first={first}
          last={last}
          onBack={onBack}
        />
      </Form>
    </Formik>
  );
};

export default YourInfoForm;
