import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import { CustomCheckGroupProps } from '../../@types';

interface AdditionalProps<T> {
  items: T;
  element: React.ComponentType<CustomCheckGroupProps<T>>;
}

function CheckGroupField<T>(
  props: AdditionalProps<T> & FieldHookConfig<string[]>
): JSX.Element {
  const [, meta, helpers] = useField(props);
  const { value } = meta;
  const { setValue } = helpers;
  const error = meta.touched && meta.error ? meta.error : undefined;
  const Element = props.element;

  const setSelected = (selected: string) => {
    const index = value.indexOf(selected);

    if (index === -1) {
      value.push(selected);
      setValue(value);
    } else {
      value.splice(index, 1);
      setValue(value);
    }
  };

  return (
    <Element
      items={props.items}
      selected={value}
      setSelected={setSelected}
      error={error}
    />
  );
}

export default CheckGroupField;
