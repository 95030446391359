import {
  BookFormState,
  RuntimeQueryHookState,
  BookPageRuntimeQuery,
  OrderTotal,
} from '../@types';
import isRuntime from './is-runtime';
import { findPackageByCarTypeId, findSelectedServices } from './search';

export default function getOrderTotal(
  state: BookFormState,
  runtimeData: RuntimeQueryHookState<BookPageRuntimeQuery>
): OrderTotal | null {
  if (!state.package || !state.vehicle || !isRuntime()) {
    return null;
  }

  let timeInMinutes = 0;
  let totalCost = 0;

  // Get package and add cost
  const selectedPackage = findPackageByCarTypeId(state, runtimeData);

  if (selectedPackage !== undefined) {
    totalCost += selectedPackage.price;
    timeInMinutes += selectedPackage.time;
  }

  const selectedServices = findSelectedServices(state, runtimeData);

  // Add cost and time of additional services
  selectedServices.forEach(additionalService => {
    totalCost += additionalService.price;
    timeInMinutes += additionalService.time;
  });

  return {
    timeInMinutes,
    totalCost,
  };
}
