import React from 'react';
import cntl from 'cntl';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { classNames } from '../../../utils';
import { CustomRadioGroupProps } from '../../../@types';

export interface ItemProps {
  value: string;
  title: string;
  meta: string;
  description: string;
}

const groupClassName = cntl`
  mt-4
  grid
  grid-cols-1
  gap-y-6
  sm:grid-cols-2
  sm:gap-x-4
`;

const optionClassName = cntl`
  relative
  flex
  cursor-pointer
  rounded-lg
  border
  bg-white
  p-4
  shadow-sm
  focus:outline-none
`;

const labelClassName = cntl`
  block
  text-sm
  font-medium
  text-gray-900
`;

const descriptionClassName = cntl`
  mt-1
  flex
  items-center
  text-sm
  text-gray-500
`;

const metaClassName = cntl`
  mt-6
  text-sm
  font-medium
  text-gray-900
`;

const circleClassName = cntl`
  pointer-events-none
  absolute
  -inset-px
  rounded-lg
`;

const CardsRadioGroup: React.FC<CustomRadioGroupProps<ItemProps[]>> = ({
  items,
  selected,
  setSelected,
}) => {
  return (
    <RadioGroup value={selected} onChange={setSelected}>
      <div className={groupClassName}>
        {items.map(item => (
          <RadioGroup.Option
            key={item.value}
            value={item.value}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'border-primary-500 ring-2 ring-primary-500' : '',
                optionClassName
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col justify-between">
                    <RadioGroup.Label as="span" className={labelClassName}>
                      {item.title}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className={descriptionClassName}
                    >
                      {item.description}
                    </RadioGroup.Description>
                    <RadioGroup.Description as="span" className={metaClassName}>
                      {item.meta}
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(
                    !checked ? 'invisible' : '',
                    'h-5 w-5 text-primary-600'
                  )}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-primary-500' : 'border-transparent',
                    circleClassName
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default CardsRadioGroup;
