import React, { useState, useEffect } from 'react';
import { gql } from 'graphql-request';
import { graphql, PageProps } from 'gatsby';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// Types
import {
  BookFormState,
  BookFormValuesUnionType,
  BookPageQuery,
  BookPageRuntimeQuery,
} from '../@types';

// Components
import Layout from '../components/book/layout';
import Nav from '../components/book/nav';
import FormLayout from '../components/book/form/layout';
import navigation from '../components/book/navigation';
import Preview from '../components/book/preview';

// Other
import useContentful from '../runtime/hooks/useContentful';
import { getAddress } from '../runtime/address-storage';
import VerifyBookForm from '../forms/verify-book';
import { useStartPasswordless } from '../runtime/hooks/useApi';

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK || '');

const Book: React.FC<PageProps<BookPageQuery>> = ({ data }) => {
  const runtimeData = useContentful<BookPageRuntimeQuery>(runtimeQuery);

  const [active, setActive] = useState(0);
  const [formState, setFormState] = useState<BookFormState>({});
  const [sentSMSCount, setSentSMSCount] = useState(0);

  const passwordlessState = useStartPasswordless(
    // We are sure at the point of
    // Sending the sms phone number is valid
    formState.yourInfo?.phone as string,
    sentSMSCount
  );

  useEffect(() => {
    const element = document.body;

    if (element !== null) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [active]);

  useEffect(() => {
    const address = getAddress();

    if (address !== null) {
      setActive(1);
      setFormState(state => ({
        ...state,
        address: {
          address,
        },
      }));
    }
  }, []);

  const onNext = (id: string, values: BookFormValuesUnionType) => {
    setFormState(state => ({
      ...state,
      [id]: {
        ...values,
      },
    }));

    if (active === navigation.length - 1) {
      setSentSMSCount(state => state + 1);
    } else {
      setActive(state => state + 1);
    }
  };

  const onBack = () => {
    setActive(state => state - 1);
  };

  return (
    <Elements stripe={stripePromise}>
      <Layout
        previewContent={<Preview state={formState} runtimeData={runtimeData} />}
        data={data}
      >
        <Nav
          activeIndex={active}
          items={navigation.map(({ navItemTitle }) => ({
            title: navItemTitle,
          }))}
        />
        {navigation.map(
          ({ id, title, autoSubmitted, component: Component }, index) => {
            const isActive = index === active;
            const isFirst = index === 0;
            const isLast = index === navigation.length - 1;

            return (
              <FormLayout id={id} key={id} title={title} active={isActive}>
                <Component
                  id={id}
                  formState={formState}
                  runtimeData={runtimeData}
                  autoSubmitted={autoSubmitted}
                  loading={passwordlessState.loading}
                  error={passwordlessState.error}
                  active={isActive}
                  first={isFirst}
                  last={isLast}
                  onBack={onBack}
                  onNext={onNext}
                />
              </FormLayout>
            );
          }
        )}
      </Layout>
      <VerifyBookForm
        open={passwordlessState.data !== 0}
        formState={formState}
        runtimeData={runtimeData}
        onClose={() => {
          return;
        }}
      />
    </Elements>
  );
};

export default Book;

const runtimeQuery = gql`
  query BookPageRuntimeQuery {
    serviceAreas: serviceAreaCollection(limit: 180) {
      items {
        sys {
          id
        }
        state
        county
        city
      }
    }
    carTypes: carTypeCollection(limit: 20, order: order_ASC) {
      items {
        sys {
          id
        }
        subTypes
        name
        packages: packagesCollection(limit: 20) {
          items {
            sys {
              id
            }
            name
            price
            time
            calloutText
            serviceType
            services: servicesCollection(limit: 20) {
              items {
                sys {
                  id
                }
                name
                description
              }
            }
            categories: serviceCategoriesCollection(limit: 3) {
              items {
                sys {
                  id
                }
              }
            }
          }
        }
        servicePricing: servicePricingCollection(limit: 20) {
          items {
            sys {
              id
            }
            price
            time
            calloutText
            service {
              sys {
                id
              }
              name
              description
              serviceCategory {
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const query = graphql`
  query BookPageQuery {
    businessPhone: contentfulSiteField(alias: { eq: "business-phone" }) {
      description {
        description
      }
    }
  }
`;
