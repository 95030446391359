import AddressForm from '../../forms/address';
import VehicleForm from '../../forms/vehicle';
import PackageForm from '../../forms/package';
import ServicesForm from '../../forms/services';
import YourInfoForm from '../../forms/your-info';
import TimeSlotForm from '../../forms/time-slot';
import PaymentForm from '../../forms/payment';

export default [
  {
    id: 'address',
    navItemTitle: 'Address',
    title: "What's your address?",
    autoSubmitted: false,
    component: AddressForm,
  },
  {
    id: 'vehicle',
    navItemTitle: 'Vehicle',
    title: 'What vehicle would you like serviced?',
    autoSubmitted: false,
    component: VehicleForm,
  },
  {
    id: 'package',
    navItemTitle: 'Package',
    title: 'Please choose a package',
    autoSubmitted: false,
    component: PackageForm,
  },
  {
    id: 'services',
    navItemTitle: 'Services',
    title: 'What additional services?',
    autoSubmitted: false,
    component: ServicesForm,
  },
  {
    id: 'yourInfo',
    navItemTitle: 'Your Info',
    title: 'Please tell us about yourself',
    autoSubmitted: false,
    component: YourInfoForm,
  },
  {
    id: 'timeSlot',
    navItemTitle: 'Time Slot',
    title: 'Pick the date and time that works',
    autoSubmitted: false,
    component: TimeSlotForm,
  },
  {
    id: 'payment',
    navItemTitle: 'Checkout',
    title: 'Checkout',
    autoSubmitted: false,
    component: PaymentForm,
  },
];
