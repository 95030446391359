import { Formik, Form } from 'formik';
import React from 'react';
import cntl from 'cntl';
import { Dialog } from '@headlessui/react';

import {
  BookFormState,
  RuntimeQueryHookState,
  BookPageRuntimeQuery,
  VerifyBookFormState,
} from '../@types';
import Spinner from '../components/spinner';
import VerifyDialog from '../components/book/verify-dialog';
import TextInput from '../components/book/form/text-input';
import TextField from './fields/text';
import { schema } from './schemas/verify-book';
import { useConfirmIntent, useVerifyAndBook } from '../runtime/hooks/useApi';
import useCapturePayment from '../runtime/hooks/useCapturePayment';
import { getTimeSlotDate } from '../runtime/timeslot-utils';
import ResendLink from '../components/book/resend-link';

const dialogTitleClassName = cntl`
  text-center
  text-lg
  leading-6
  font-medium
  text-gray-900
`;

const dialogDescriptionClassName = cntl`
  text-sm
  text-gray-500
`;

const errorMessageClassName = cntl`
  text-red-500
  text-sm mt-2
`;

const buttonWrapperClassName = cntl`
  mt-5
  sm:mt-6
  flex
  w-full
  justify-end
`;

const buttonClassName = cntl`
  ml-4
  whitespace-nowrap
  inline-flex
  items-center
  justify-center
  px-4
  py-2
  borderborder-transparent
  rounded-md
  shadow-sm
  text-base
  font-medium
  focus:outline-none
  text-white
  bg-primary-600
  hover:bg-primary-700
  disabled:opacity-30
`;

const spinnerClassName = cntl`
  text-white
  h-5
  w-5
  mr-2
`;

interface VerifyBookFormProps {
  open: boolean;
  onClose: () => void;
  formState: BookFormState;
  runtimeData: RuntimeQueryHookState<BookPageRuntimeQuery>;
}

const VerifyBookForm: React.FC<VerifyBookFormProps> = ({
  open,
  onClose,
  formState,
  runtimeData,
}) => {
  const initialValues: VerifyBookFormState = {
    code: '',
  };

  // Configure verify and booking hook
  const {
    state: bookingIntentState,
    start: startVerifyAndBook,
  } = useVerifyAndBook(formState, runtimeData);

  // Configure payment handler
  const paymentIntentSecret = bookingIntentState.data?.secret;

  const paymentState = useCapturePayment(paymentIntentSecret);

  // Configure booking confirmation hook
  useConfirmIntent(
    bookingIntentState.data?.access_token,
    bookingIntentState.data?.booking._id
  );

  const onSubmit = (values: VerifyBookFormState) => {
    startVerifyAndBook(values.code);
  };

  return (
    <VerifyDialog
      appointmentDate={getTimeSlotDate(formState.timeSlot?.timeSlot)}
      success={
        bookingIntentState.data !== null &&
        paymentState.error === undefined &&
        paymentState.data !== null
      }
      paymentDeclined={paymentState.error !== undefined}
      error={typeof bookingIntentState.error !== 'undefined'}
      open={open}
      onClose={onClose}
    >
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        <Form>
          <Dialog.Title as="h3" className={dialogTitleClassName}>
            Verify your phone
          </Dialog.Title>
          <div className="mt-2">
            <p className={dialogDescriptionClassName}>
              Please enter the verification code, we've sent to your phone
              number. Didn't receive?{' '}
              <ResendLink phone={formState.yourInfo?.phone} />
            </p>
            <p className={errorMessageClassName}>{bookingIntentState.error}</p>
          </div>

          <div className="mt-4">
            <TextField
              type="text"
              placeholder="Enter code here"
              element={TextInput}
              name="code"
            />
          </div>

          <div className={buttonWrapperClassName}>
            <button
              disabled={bookingIntentState.loading}
              type="submit"
              className={buttonClassName}
            >
              {bookingIntentState.loading || paymentState.loading ? (
                <Spinner className={spinnerClassName} />
              ) : null}
              Verify and Book
            </button>
          </div>
        </Form>
      </Formik>
    </VerifyDialog>
  );
};

export default VerifyBookForm;
