import React from 'react';
import cntl from 'cntl';
import { ExclamationIcon } from '@heroicons/react/solid';

interface AlertProps {
  title: string;
}

const containerClassName = cntl`
  rounded-md
  bg-red-50
  p-4
`;

const iconClassName = cntl`
  h-5
  w-5
  text-red-400
`;

const titleClassName = cntl`
  text-sm
  font-medium
  text-red-800
`;

const descriptionClassName = cntl`
  mt-2
  text-sm
  text-red-700
`;

const Alert: React.FC<AlertProps> = ({ children, title }) => {
  return (
    <div className={containerClassName}>
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon className={iconClassName} aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className={titleClassName}>{title}</h3>
          <div className={descriptionClassName}>
            <p>{children}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
