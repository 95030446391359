import React from 'react';
import cntl from 'cntl';
import { classNames } from '../../utils';

interface NavProps {
  activeIndex: number;
  items: Array<NavItem>;
}

interface NavItem {
  title: string;
}

export interface ItemProps {
  title: string;
}

const navClassName = cntl`
  flex
  mb-10
`;

const navItemClassName = cntl`
  h-2
  w-14
  rounded-md
`;

const Nav: React.FC<NavProps> = ({ items, activeIndex }) => {
  return (
    <div className={navClassName}>
      {items.map(({ title }, index) => (
        <div
          key={title}
          className={classNames(
            index === 0 ? 'mr-1' : 'mx-1',
            index < activeIndex
              ? 'bg-primary-400'
              : index === activeIndex
              ? 'bg-primary-500'
              : 'bg-gray-300',
            navItemClassName
          )}
        />
      ))}
    </div>
  );
};

export default Nav;
