import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { AddressFormState, BookPageFormProps } from '../@types';
import FormFooter from '../components/book/form/footer';
import { schema } from './schemas/address';
import Row from '../components/book/form/row';
import MapInput from '../components/book/form/map-input';
import MapAutocompleteField from './fields/map-autocomplete';
import isPlaceSupported from '../runtime/is-place-supported';
import { getAddress } from '../runtime/address-storage';

const AddressForm: React.FC<BookPageFormProps> = ({
  id,
  active,
  first,
  last,
  autoSubmitted,
  runtimeData,
  onNext,
  onBack,
}) => {
  const addressFromStorage = getAddress();

  const initialValues: AddressFormState = {
    address: getAddress(),
  };

  const onSubmit = (
    values: AddressFormState,
    helpers: FormikHelpers<AddressFormState>
  ) => {
    const { data } = runtimeData;
    const { address } = values;

    if (data === null || address === null) {
      return;
    }

    if (!isPlaceSupported(data.serviceAreas, address)) {
      helpers.setErrors({
        address: 'Service is not available in provided address',
      });
      return;
    }

    onNext(id, values);
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <Form
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === 'Enter') {
            event.preventDefault();
          }
        }}
      >
        <Row>
          <MapAutocompleteField
            defaultPlace={addressFromStorage ?? undefined}
            disabled={runtimeData.loading}
            name="address"
            element={MapInput}
            placeholder="Enter your address"
          />
        </Row>
        <FormFooter
          autoSubmitted={autoSubmitted}
          active={active}
          first={first}
          last={last}
          onBack={onBack}
        />
      </Form>
    </Formik>
  );
};

export default AddressForm;
