import React, { useState } from 'react';
import cntl from 'cntl';
import { RadioGroup } from '@headlessui/react';
import { classNames } from '../../../utils';
import {
  ContentfulPackage,
  CustomRadioGroupProps,
  ItemList,
} from '../../../@types';

const optionClassName = cntl`
  relative
  block
  border
  border-gray-300
  bg-white
  shadow-sm
  px-6
  py-4
  cursor-pointer
  hover:border-gray-400
  sm:flex
  sm:justify-between
  focus:outline-none
`;

const activeOptionClassName = cntl`
  ring-1
  ring-offset-2
  ring-primary-500
`;

const optionWrapperClassName = cntl`
  flex
  items-center
`;

const calloutTextClassName = cntl`
  inline-flex
  px-4
  py-1
  mb-1
  rounded-full
  text-xs
  font-semibold
  tracking-wide
  bg-primary-100
  text-primary-600
`;

const optionLabelClassName = cntl`
  font-medium
  text-gray-900
`;

const optionDescriptionClassName = cntl`
  mt-2
  flex
  text-sm
  sm:mt-0
  sm:block
  sm:ml-4
  sm:text-right
`;

const packageTotalTimeClassName = cntl`
  ml-1
  text-gray-900
  sm:ml-0
`;

const serviceExpanderClassName = cntl`
  sm:inline
  underline
  cursor-pointer
`;

const serviceContainerClassName = cntl`
  flow-root
  my-6
`;

const serviceListClassName = cntl`
  -my-5
  divide-y
  divide-gray-200
`;

const serviceHeadingClassName = cntl`
  text-sm
  font-semibold
  text-gray-800
  focus:outline-none
`;

const absoluteContainerClassName = cntl`
  absolute
  -inset-px
  border-2
  pointer-events-none
`;

const errorMessageClassName = cntl`
  mt-2
  text-sm
  text-red-600
`;

const PackageRadioGroup: React.FC<
  CustomRadioGroupProps<ItemList<ContentfulPackage>>
> = ({ items, selected, setSelected, error }) => {
  const [collapedItemId, setCollapsedItemId] = useState('');

  const setCollapsed = (event: React.MouseEvent, p: ContentfulPackage) => {
    event.stopPropagation();
    setCollapsedItemId(() => (collapedItemId === p.sys.id ? '' : p.sys.id));
  };

  return (
    <RadioGroup value={selected} onChange={setSelected}>
      <div className="space-y-4">
        {error && <p className={errorMessageClassName}>{error}</p>}
        {items.items.map(p => (
          <RadioGroup.Option
            key={p.sys.id}
            value={p.sys.id}
            className={({ active }) =>
              classNames(active ? activeOptionClassName : '', optionClassName)
            }
          >
            {({ checked }) => (
              <>
                <div className={optionWrapperClassName}>
                  <div className="text-sm">
                    {p.calloutText !== null ? (
                      <div className={calloutTextClassName}>
                        {p.calloutText}
                      </div>
                    ) : null}
                    <RadioGroup.Label as="p" className={optionLabelClassName}>
                      {p.name}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="div" className="text-gray-500">
                      <p
                        onClick={(event: React.MouseEvent) =>
                          setCollapsed(event, p)
                        }
                        className={serviceExpanderClassName}
                      >
                        See what's included
                      </p>
                    </RadioGroup.Description>
                    {collapedItemId === p.sys.id && (
                      <div>
                        <div className={serviceContainerClassName}>
                          <ul className={serviceListClassName}>
                            {p.services.items.map(s => (
                              <li key={s.sys.id} className="py-2">
                                <div className="relative">
                                  <h3 className={serviceHeadingClassName}>
                                    <span
                                      className="absolute inset-0"
                                      aria-hidden="true"
                                    />
                                    {s.name}
                                  </h3>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <RadioGroup.Description
                  as="div"
                  className={optionDescriptionClassName}
                >
                  <div className="font-medium">${p.price}</div>
                  <div className={packageTotalTimeClassName}>
                    / {p.time} min
                  </div>
                </RadioGroup.Description>
                <div
                  className={classNames(
                    checked ? 'border-primary-500' : 'border-transparent',
                    absoluteContainerClassName
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default PackageRadioGroup;
