import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/gatsby';
import { RuntimeQueryHookState } from '../../@types';

export default (
  origin: string | undefined,
  destinations: string[]
): RuntimeQueryHookState<Array<number>> => {
  const [state, setState] = useState<RuntimeQueryHookState<Array<number>>>({
    loading: false,
    error: false,
    data: null,
  });

  useEffect(() => {
    if (origin === undefined || destinations.length === 0) {
      return;
    }

    setState(state => ({ ...state, loading: true }));

    try {
      (async () => {
        const service = new google.maps.DistanceMatrixService();

        const response = await service.getDistanceMatrix({
          origins: [origin],
          destinations,
          travelMode: google.maps.TravelMode.DRIVING,
        });

        if (response !== null) {
          setState(state => ({
            ...state,
            data: response.rows[0].elements.map(element =>
              Math.round(element.duration.value / 60)
            ),
            loading: false,
          }));
        }
      })();
    } catch (error) {
      setState(state => ({
        ...state,
        error: true,
        loading: false,
      }));
      Sentry.captureException(error);
    }
  }, [origin, destinations.length]);

  return state;
};
