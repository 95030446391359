import React, { useState } from 'react';
import cntl from 'cntl';
import { RadioGroup } from '@headlessui/react';
import { classNames } from '../../../utils';
import {
  ContentfulServicePricing,
  CustomCheckGroupProps,
} from '../../../@types';

const optionClassName = cntl`
  relative
  block
  border
  border-gray-300
  bg-white
  shadow-sm
  px-6
  py-4
  cursor-pointer
  hover:border-gray-400
  sm:flex
  sm:justify-between
  focus:outline-none
  md:grid
  md:grid-cols-12
`;

const activeOptionClassName = cntl`
  ring-1
  ring-offset-2
  ring-primary-500
`;

const optionWrapperClassName = cntl`
  flex
  items-center
  md:col-span-9
`;

const calloutTextClassName = cntl`
  inline-flex
  px-4
  py-1
  mb-1
  rounded-full
  text-xs
  font-semibold
  tracking-wide
  bg-primary-100
  text-primary-600
`;

const optionLabelClassName = cntl`
  font-medium
  text-gray-900
`;

const optionDescriptionClassName = cntl`
  mt-2
  flex
  text-sm
  sm:mt-0
  sm:block
  sm:ml-4
  sm:text-right
  md:col-span-3
`;

const serviceExpanderClassName = cntl`
  sm:inline
  underline
  cursor-pointer
`;

const serviceContainerClassName = cntl`
  flow-root
  my-6
`;

const absoluteContainerClassName = cntl`
  absolute
  -inset-px
  border-2
  pointer-events-none
`;

const ServiceCheckGroup: React.FC<
  CustomCheckGroupProps<ContentfulServicePricing[]>
> = ({ items, selected, setSelected }) => {
  const [collapedItemId, setCollapsedItemId] = useState('');

  const setCollapsed = (
    event: React.MouseEvent,
    p: ContentfulServicePricing
  ) => {
    event.stopPropagation();
    setCollapsedItemId(() => (collapedItemId === p.sys.id ? '' : p.sys.id));
  };

  const isSelected = (item: ContentfulServicePricing) => {
    return selected.indexOf(item.sys.id) !== -1;
  };

  return (
    <RadioGroup value={'FREEZED'} onChange={setSelected}>
      <div className="space-y-4">
        {items.map(s => (
          <RadioGroup.Option
            key={s.sys.id}
            value={s.sys.id}
            className={({ active }) =>
              classNames(active ? activeOptionClassName : '', optionClassName)
            }
          >
            <>
              <div className={optionWrapperClassName}>
                <div className="text-sm">
                  {s.calloutText !== null ? (
                    <div className={calloutTextClassName}>{s.calloutText}</div>
                  ) : null}
                  <RadioGroup.Label as="p" className={optionLabelClassName}>
                    {s.service.name}
                  </RadioGroup.Label>
                  <RadioGroup.Description as="div" className="text-gray-500">
                    <p
                      onClick={(event: React.MouseEvent) =>
                        setCollapsed(event, s)
                      }
                      className={serviceExpanderClassName}
                    >
                      Learn more
                    </p>
                  </RadioGroup.Description>
                  {collapedItemId === s.sys.id && (
                    <div>
                      <div className={serviceContainerClassName}>
                        {s.service.description}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <RadioGroup.Description
                as="div"
                className={optionDescriptionClassName}
              >
                <div className="font-medium">${s.price}</div>
                <div className="ml-1 text-gray-900 sm:ml-0">/ {s.time} min</div>
              </RadioGroup.Description>
              <div
                className={classNames(
                  isSelected(s) ? 'border-primary-500' : 'border-transparent',
                  absoluteContainerClassName
                )}
                aria-hidden="true"
              />
            </>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default ServiceCheckGroup;
