import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/gatsby';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { RuntimeActionHookState } from '../../@types';
import isRuntime from '../is-runtime';

export default function useCapturePayment(
  paymentIntentSecret: string | null | undefined
): RuntimeActionHookState<string> {
  const [state, setState] = useState<RuntimeActionHookState<string>>({
    loading: false,
    error: undefined,
    data: null,
  });

  // Configure stripe hooks
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (
      !isRuntime() ||
      !paymentIntentSecret ||
      stripe === null ||
      elements === null
    ) {
      return;
    }

    const card = elements.getElement(CardElement);

    // Check if card element is not empty
    if (card === null) {
      return;
    }

    setState(state => ({ ...state, loading: true }));

    (async () => {
      try {
        const result = await stripe.confirmCardPayment(paymentIntentSecret, {
          payment_method: {
            card,
          },
        });

        if (result.error) {
          throw new Error(`${result.error.message}`);
        }

        setState(state => ({ ...state, data: result.paymentIntent.id }));
      } catch (err) {
        setState(state => ({
          ...state,
          error:
            err instanceof Error
              ? err.message
              : 'Error occurred while capturing payment',
        }));
        Sentry.captureException(err);
      } finally {
        setState(state => ({ ...state, loading: false }));
      }
    })();
  }, [paymentIntentSecret]);

  return state;
}
