import React from 'react';
import cntl from 'cntl';
import { PageSectionProps, BookPageQuery } from '../../@types';
import Header from '../header/header';
import GrayRects from '../landing/hero/gray-rects';
import SEO from '../seo';

const mainWrapperClassName = cntl`
  relative
  bg-gray-100
  min-h-screen
  overflow-hidden
`;

const contentWrapperClassName = cntl`
  relative
  pt-6
  pb-16
  sm:pb-24
  pb-32
`;

const mainClassName = cntl`
  pt-16
  mx-auto
  max-w-7xl
  px-4
  sm:px-6
  relative
`;

const containerClassName = cntl`
  max-w-2xl
  mx-auto
  pt-16
  pb-24
  px-4
  sm:px-6
  lg:max-w-7xl
  lg:px-8
`;

const gridClassName = cntl`
  lg:grid
  lg:grid-cols-2
  lg:gap-x-12
  xl:gap-x-16
`;

const formClassName = cntl`
  relative
  sm:max-w-md
  md:max-w-lg
`;

const previewClassName = cntl`
  mt-10
  md:mt-0
  sm:max-w-md
  md:max-w-lg
`;

const previewWrapperClassName = cntl`
  bg-white
  rounded-lg
  shadow-lg
`;

interface AdditionalProps {
  previewContent: React.ReactNode;
}

const BookPageLayout: React.FC<
  PageSectionProps<BookPageQuery> & AdditionalProps
> = ({ children, data, previewContent }) => {
  const title = 'Mobile Car Detail Booking in Roseville, CA Area';
  const description =
    'A quick online mobile detail booking system allows you to choose to add your car, select your service, pick a date and time, and securely pay and schedule your detailing appointment.';

  return (
    <>
      <SEO title={title} description={description} useTitleTemplate={false} />
      <div className={mainWrapperClassName}>
        <GrayRects />
        <div className={contentWrapperClassName}>
          <Header
            phoneNumber={data.businessPhone.description.description}
            hasMainCTA={false}
            navigation={[]}
            isCentered
          />
          <main className={mainClassName}>
            <div className={containerClassName}>
              <div className={gridClassName}>
                <div className={formClassName}>{children}</div>
                <div className={previewClassName}>
                  <div className={previewWrapperClassName}>
                    {previewContent}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default BookPageLayout;
