import * as Yup from 'yup';

export const schema = Yup.object().shape({
  address: Yup.object()
    .shape({
      formatted_address: Yup.string(),
      place_id: Yup.string(),
      address_components: Yup.array(),
    })
    .nullable()
    .required('Address is required'),
});
