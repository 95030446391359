import {
  ApiResponse,
  BookingIntentInput,
  BookingIntentResponse,
  SetupUserResponse,
  StartPasswordlessResponse,
  VerifyAuthResponse,
} from '../../@types';

// Back end API url
const BASE_URL = process.env.GATSBY_BACK_END_URL;

/**
 * Client utility for interacting with back end API
 * @param path Path to add to the base url
 */
export default function apiClient(
  path: string,
  options: RequestInit
): Promise<Response> {
  return fetch(`${BASE_URL}${path}`, options);
}

/**
 * Start passwordless authentication
 * @param path Phone phone to send sms to
 */
export async function startPasswordless(
  phone: string
): Promise<ApiResponse<StartPasswordlessResponse>> {
  const response = await apiClient('/passwordless', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      phone,
    }),
  });

  if (!response.ok) {
    throw new Error('INAPP::Failed to send a verification code.');
  }

  return response.json();
}

/**
 * Verify authentication code
 * @param path Phone number which received the code
 * @param code Verification code
 */
export async function verifyAuth(
  phone: string,
  code: string
): Promise<ApiResponse<VerifyAuthResponse>> {
  const response = await apiClient('/verify-auth', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      phone,
      code,
    }),
  });

  if (!response.ok) {
    throw new Error('INAPP::Failed to verify phone number.');
  }

  return response.json();
}

/**
 * Setup user profile
 * @param access_token Authorization token
 * @param firstName Users first name
 * @param lastName Users last name
 * @param email Users email
 */
export async function setupUser(
  access_token: string,
  firstName: string | undefined,
  lastName: string | undefined,
  email: string | undefined
): Promise<ApiResponse<SetupUserResponse>> {
  const response = await apiClient('/setup-user', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify({
      firstName,
      lastName,
      email,
    }),
  });

  if (!response.ok) {
    throw new Error(
      'INAPP::Failed to setup profile. Please re-load the page and try again.'
    );
  }

  return response.json();
}

/**
 * Create booking intent
 * @param access_token Authorization token
 * @param bookingIntent Booking intent input
 */
export async function createBookingIntent(
  access_token: string,
  bookingIntent: BookingIntentInput
): Promise<ApiResponse<BookingIntentResponse>> {
  const response = await apiClient('/booking-intent', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify(bookingIntent),
  });

  if (!response.ok) {
    throw new Error(
      'INAPP::Failed to create booking. Please re-load the page and try again.'
    );
  }

  return response.json();
}

/**
 * Confirm booking intent
 * @param access_token Authorization token
 * @param bookingId Id of newly created booking
 */
export async function confirmBookingIntent(
  access_token: string,
  bookingId: string
): Promise<Response> {
  return apiClient('/confirm-intent', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify({ bookingId }),
  });
}
