import {
  Year,
  Make,
  Model,
  SelectMenuValue,
  RuntimeQueryHookState,
  BookPageRuntimeQuery,
} from '../@types';
import isRuntime from './is-runtime';

export const transformYears = (years: Year[] | null): SelectMenuValue[] => {
  if (years === null || !isRuntime()) {
    return [];
  }

  return years.map(({ year }) => ({ value: year, label: year })).reverse();
};

export const transformMakes = (makes: Make[] | null): SelectMenuValue[] => {
  if (makes === null || !isRuntime()) {
    return [];
  }

  return makes.map(({ make }) => ({ value: make, label: make }));
};

export const transformModels = (models: Model[] | null): SelectMenuValue[] => {
  if (models === null || !isRuntime()) {
    return [];
  }

  return models.map(({ model }) => ({ value: model, label: model }));
};

export const transformColors = (): SelectMenuValue[] => {
  const colors = [
    'Black',
    'Blue',
    'Brown',
    'Gold',
    'Gray',
    'Green',
    'Orange',
    'Purple',
    'Red',
    'Beige',
    'Silver',
    'White',
    'Yellow',
  ];

  return colors.map(label => ({
    label,
    value: label,
  }));
};

export const transformCarTypes = (
  runtimeData: RuntimeQueryHookState<BookPageRuntimeQuery>
): SelectMenuValue[] => {
  const items: SelectMenuValue[] = [];

  if (!isRuntime() || runtimeData.data === null) {
    return [];
  }

  for (const carType of runtimeData.data.carTypes.items) {
    for (const subType of carType.subTypes) {
      items.push({
        label: subType,
        value: carType.sys.id,
      });
    }
  }

  return items;
};
