import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/gatsby';
import moment, { Moment } from 'moment';
import { CalendarEvent, RuntimeQueryHookState } from '../../@types';
import calendarClient from '../client/calendar';
import isRuntime from '../is-runtime';

const defaultTimezone = process.env.GATSBY_G_CALENDAR_TIMEZONE || '';

export default (
  day: Moment | null,
  startHour = 9,
  timeZone = defaultTimezone
): RuntimeQueryHookState<Array<CalendarEvent>> => {
  const [state, setState] = useState<
    RuntimeQueryHookState<Array<CalendarEvent>>
  >({
    loading: false,
    error: false,
    data: null,
  });

  useEffect(() => {
    if (!day || !isRuntime()) {
      return;
    }

    (async () => {
      setState(state => ({ ...state, loading: true }));

      try {
        // Fetch events from 9 AM - 5 PM
        // If today and current time is in range 9 AM - 5 PM
        // Fetch events from current time to 5 pm
        const timeMin = moment(day).hour(startHour).minute(0).format();
        const timeMax = moment(day).hour(17).minute(0).format();

        const params = {
          timeZone,
          timeMin,
          timeMax,
          showDeleted: false,
          singleEvents: true,
          orderBy: 'startTime',
        };

        const response = await calendarClient(params);

        if (!response.ok) {
          setState(state => ({
            ...state,
            error: true,
          }));
        } else {
          const { items } = await response.json();
          setState(state => ({
            ...state,
            data: items.length ? items : null,
            loading: false,
          }));
        }
      } catch (err) {
        setState(state => ({
          ...state,
          error: true,
          loading: false,
        }));
        Sentry.captureException(err);
      }
    })();
  }, [day?.format(), startHour]);

  return state;
};
