import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { BookPageFormProps, PaymentFormState, CardInputValue } from '../@types';
import FormFooter from '../components/book/form/footer';
import { schema } from './schemas/payment';
import FormRow from '../components/book/form/row';
import CardInput from '../components/book/form/card-input';
import CardsRadioGroup, {
  ItemProps,
} from '../components/book/form/cards-radio-group';
import getOrderTotal from '../runtime/get-order-total';
import RadioGroupField from './fields/radio-group';

const PaymentForm: React.FC<BookPageFormProps> = ({
  id,
  active,
  first,
  last,
  loading,
  error,
  formState,
  runtimeData,
  autoSubmitted,
  onNext,
  onBack,
}) => {
  const [cardState, setCardState] = useState<CardInputValue>({
    error: undefined,
    complete: false,
  });

  const initialValues: PaymentFormState = {
    paymentAmount: 'deposit',
  };

  // Handle card change event separately
  const onChange = ({ error, complete }: StripeCardElementChangeEvent) => {
    setCardState(state => ({
      ...state,
      error: error === undefined ? error : error.message,
      complete,
    }));
  };

  const onSubmit = (values: PaymentFormState) => {
    if (cardState.error || !cardState.complete) {
      return;
    }

    onNext(id, values);
  };

  const total = getOrderTotal(formState, runtimeData)?.totalCost || 0;
  const deposit = ((total * 10) / 100).toFixed(2);

  const items: ItemProps[] = [
    {
      value: 'deposit',
      title: 'Pay 10% Deposit',
      description: 'Remaining balance due upon completion',
      meta: `$${deposit}`,
    },
    {
      value: 'full',
      title: 'Pay Total Cost',
      description: 'Refundable up to 24 hours before appointment',
      meta: `$${total}`,
    },
  ];

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {props => (
        <Form>
          <FormRow>
            <RadioGroupField
              name="paymentAmount"
              items={items}
              element={CardsRadioGroup}
            />
          </FormRow>
          <FormRow>
            <CardInput
              onChange={onChange}
              error={
                cardState.error !== undefined
                  ? cardState.error
                  : !cardState.complete && props.submitCount !== 0
                  ? 'Please complete card information'
                  : undefined
              }
            />
          </FormRow>
          <FormFooter
            autoSubmitted={autoSubmitted}
            active={active}
            first={first}
            last={last}
            loading={loading}
            error={error}
            onBack={onBack}
          />
        </Form>
      )}
    </Formik>
  );
};

export default PaymentForm;
