import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import { CustomPhoneInputProps } from '../../@types';

interface AdditionalProps {
  element: React.ComponentType<CustomPhoneInputProps>;
}

const PhoneField: React.FC<
  AdditionalProps & FieldHookConfig<string>
> = props => {
  const [, meta, helpers] = useField(props);
  const error = meta.touched && meta.error ? meta.error : undefined;
  const { value } = meta;
  const { setValue } = helpers;

  const Element = props.element;

  const handleChange = (value: string | undefined) => setValue(value || '');

  return (
    <Element
      value={value}
      onChange={handleChange}
      error={error}
      disabled={props.disabled}
      placeholder={props.placeholder}
    />
  );
};

export default PhoneField;
