import React, { useState } from 'react';
import cntl from 'cntl';
import { RadioGroup } from '@headlessui/react';
import { DayPickerSingleDateController } from 'react-dates';
import { classNames } from '../../../utils';
import { CustomSlotPickerProps, SelectMenuValue } from '../../../@types';
import { isOutsideRange } from '../../../runtime/timeslot-utils';

const errorClassName = cntl`
  mt-2
  mb-4
  text-md
  text-red-600
`;

const dayPickerWrapperClassName = cntl`
  relative
  mr-0
  mb-6
  xl:mr-6
  xl:mb-6
`;

const slotGridClassName = cntl`
  grid
  grid-cols-2
  gap-2
`;

const slotOptionClassName = cntl`
  relative
  block
  border
  border-gray-300
  bg-white
  shadow-sm
  px-2
  py-4
  cursor-pointer
  hover:border-gray-400
  sm:flex
  sm:justify-center
  focus:outline-none
`;

const slotOptionWrapperClassName = cntl`
  flex
  items-center
  text-sm
  lg:text-xs
`;

const slotOptionLabelClassName = cntl`
  font-medium
  text-gray-900
`;

const slotOverlayClassName = cntl`
  absolute
  -inset-px
  border-2
  pointer-events-none
`;

const TimeSlotInput: React.FC<CustomSlotPickerProps> = ({
  error,
  items,
  loading,
  selected,
  onDateChange,
  onSlotChange,
  shouldRenderPicker,
}) => {
  const [focused, setFocused] = useState(false);

  const isChecked = (option: SelectMenuValue): boolean => {
    return selected?.slot?.value === option.value;
  };

  return (
    <>
      {error && <p className={errorClassName}>{error}</p>}
      <>
        <div className="xl:flex">
          {shouldRenderPicker && (
            <div className={dayPickerWrapperClassName}>
              <DayPickerSingleDateController
                initialVisibleMonth={null}
                date={selected === null ? null : selected.date}
                isOutsideRange={isOutsideRange}
                focused={focused}
                numberOfMonths={1}
                onDateChange={onDateChange}
                onFocusChange={({ focused }) => setFocused(focused)}
              />
            </div>
          )}
          <div>
            {loading ? (
              <div>Loading...</div>
            ) : items.length !== 0 ? (
              <RadioGroup value={selected?.slot} onChange={onSlotChange}>
                <div className={slotGridClassName}>
                  {items.map((option: SelectMenuValue) => (
                    <RadioGroup.Option
                      key={option.value}
                      value={option}
                      className={slotOptionClassName}
                    >
                      <>
                        <div className={slotOptionWrapperClassName}>
                          <RadioGroup.Label
                            as="p"
                            className={slotOptionLabelClassName}
                          >
                            {option.label}
                          </RadioGroup.Label>
                        </div>
                        <div
                          className={classNames(
                            isChecked(option)
                              ? 'border-primary-500'
                              : 'border-transparent',
                            slotOverlayClassName
                          )}
                          aria-hidden="true"
                        />
                      </>
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            ) : (
              <div>No available slots</div>
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default TimeSlotInput;
