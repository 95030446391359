import moment, { Moment } from 'moment';
import isRuntime from './is-runtime';
import {
  CalendarEvent,
  RuntimeQueryHookState,
  SelectMenuValue,
  TimeSlotValue,
} from '../@types';

export function formatMinutes(timeInMinutes: number): string {
  if (timeInMinutes < 60) {
    return `${timeInMinutes} mins`;
  }

  const mod = timeInMinutes % 60;
  const hours = (timeInMinutes - mod) / 60;

  return mod === 0 ? `${hours} hrs` : `${hours} hrs ${mod} minutes`;
}

export function getTimeSlotDate(
  timeSlot: TimeSlotValue | undefined | null
): Moment {
  return moment(timeSlot?.date)
    .startOf('day')
    .add(Number(timeSlot?.slot?.value), 'minutes');
}

export function isOutsideRange(day: Moment): boolean {
  return day.isBefore(moment());
}

interface TimelinePoint {
  startMins: number;
  duration: number;
  travelTime: number;
}

export function generateTimeSlots(
  events: RuntimeQueryHookState<CalendarEvent[]>,
  travelTimes: RuntimeQueryHookState<number[]>,
  appointmentDuration: number | undefined
): SelectMenuValue[] {
  const bufferTimeinMins = 20;
  const allowedTravelTime = 35;

  if (
    !isRuntime() ||
    typeof appointmentDuration === 'undefined' ||
    travelTimes.loading ||
    events.loading
  ) {
    return [];
  }

  const timeline: TimelinePoint[] = [];
  const slots: SelectMenuValue[] = [];

  // First point of the timeline is 9:00 AM
  // Start of the working day - bufferTimeinMins
  timeline.push({
    startMins: 9 * 60 - bufferTimeinMins,
    duration: 0,
    travelTime: 0,
  });

  if (
    !events.loading &&
    events.data !== null &&
    !travelTimes.loading &&
    travelTimes.data !== null
  ) {
    for (const [i, event] of events.data.entries()) {
      const eventStart = moment(event.start.dateTime);
      const eventEnd = moment(event.end.dateTime);

      const duration = eventEnd.diff(eventStart, 'minutes');

      // Travel time between scheduled appointment
      // And current appointment addresses
      const travelTime = travelTimes.data[i];

      timeline.push({
        startMins: eventStart.hours() * 60 + eventStart.minutes(),
        duration,
        travelTime,
      });
    }
  }

  // Last point of the timeline is 5:00 PM
  // End of the working day
  timeline.push({
    startMins: 17 * 60,
    duration: 0,
    travelTime: 0,
  });

  for (let i = 0; i < timeline.length - 1; i++) {
    const event = timeline[i];
    const nextEvent = timeline[i + 1];

    // Check travel time restrictions
    if (event.travelTime > allowedTravelTime || nextEvent.travelTime > 35) {
      continue;
    }

    // Free time available between two events
    const freeTime =
      nextEvent.startMins -
      nextEvent.travelTime -
      bufferTimeinMins -
      (event.startMins + event.duration + event.travelTime + bufferTimeinMins);

    const fittingSlotCount = Math.floor(freeTime / appointmentDuration);

    if (fittingSlotCount <= 0) {
      continue;
    }

    for (let j = 0; j < fittingSlotCount; j++) {
      const slotStartInMins =
        event.startMins +
        event.duration +
        event.travelTime +
        bufferTimeinMins +
        j * appointmentDuration;
      const slotStartHour = Math.floor(slotStartInMins / 60);
      const slotStartMinute = slotStartInMins % 60;

      const formatted = moment()
        .startOf('day')
        .hour(slotStartHour)
        // Round minutes to nearest multiple of 5
        .minutes(Math.round(slotStartMinute / 5) * 5)
        .format('hh:mm a');

      slots.push({
        label: formatted,
        value: slotStartInMins.toString(),
      });
    }
  }

  return slots;
}
