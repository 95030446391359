// Vehicle data api
// Documentation: https://documenter.getpostman.com/view/185623/TVYM5c17
const BASE_URL = 'https://vehapi.com/api/v1';

// Authorization key
const BEARER_TOKEN = process.env.GATSBY_VEHAPI_TOKEN;

/**
 * Client utility for interacting with https://vehapi.com API
 * @param path Path to add to the base url
 */
export default async function carAPIClient(path: string): Promise<Response> {
  return fetch(`${BASE_URL}/${path}`, {
    headers: {
      authorization: `Bearer ${BEARER_TOKEN}`,
    },
  });
}
