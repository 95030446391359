import React from 'react';
import { Moment } from 'moment';
import { useField, FieldHookConfig } from 'formik';

import useEvents from '../../runtime/hooks/useEvents';
import useTravelTime from '../../runtime/hooks/useTravelTime';
import { generateTimeSlots } from '../../runtime/timeslot-utils';

import {
  CustomSlotPickerProps,
  SelectMenuValue,
  TimeSlotValue,
} from '../../@types';
import '../../components/book/datepicker-theme';

interface AdditionalProps {
  appointmentAddress: string | undefined;
  appointmentDuration: number | undefined;
  shouldRenderPicker: boolean;
  element: React.ComponentType<CustomSlotPickerProps>;
}

const TimeSlotField: React.FC<
  AdditionalProps & FieldHookConfig<TimeSlotValue>
> = props => {
  const [, meta, helpers] = useField(props);
  const { value } = meta;
  const { setValue } = helpers;
  const error =
    meta.touched && meta.error ? 'Please select a time slot' : undefined;

  const events = useEvents(value?.date);

  const travelTimes = useTravelTime(
    props.appointmentAddress,
    events.data === null ? [] : events.data.map(event => event.location)
  );

  let availableSlots: SelectMenuValue[] = [];

  if (value?.date) {
    availableSlots = generateTimeSlots(
      events,
      travelTimes,
      props.appointmentDuration
    );
  }

  const Element = props.element;

  const onDateChange = (date: Moment | null) => {
    setValue({ date, slot: null });
  };

  const onSlotChange = (slot: SelectMenuValue) => {
    if (value === null) {
      return;
    }

    setValue({ ...value, slot });
  };

  return (
    <Element
      selected={value}
      onDateChange={onDateChange}
      onSlotChange={onSlotChange}
      error={error}
      items={availableSlots}
      shouldRenderPicker={props.shouldRenderPicker}
      loading={events.loading || travelTimes.loading}
    />
  );
};

export default TimeSlotField;
