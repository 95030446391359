import * as Yup from 'yup';

export const schema = Yup.object().shape({
  timeSlot: Yup.object()
    .shape({
      date: Yup.object().nullable().required('Please pick a date'),
      slot: Yup.object().nullable().required('Please pick a time'),
    })
    .nullable()
    .required('Please select the time slot'),
});
