import React, { useState, useEffect } from 'react';
import cntl from 'cntl';
import { useStartPasswordless } from '../../runtime/hooks/useApi';

const linkClassName = cntl`
  text-primary-500
  hover:underline
`;

interface ResendLinkProps {
  phone: string | undefined;
}

const ResendLink: React.FC<ResendLinkProps> = ({ phone }) => {
  const [canResendIn, setCanResendIn] = useState(60);
  const [sentCount, setSentCount] = useState(0);

  // Configure re-send message hook
  const passwordlessState = useStartPasswordless(phone, sentCount);

  useEffect(() => {
    const interval = setInterval(() => {
      setCanResendIn(state => {
        return state - 1;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (sentCount !== 0 && passwordlessState.data === sentCount) {
      setCanResendIn(60);
    }
  }, [sentCount, passwordlessState.data]);

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();

    if (canResendIn > 0) {
      return;
    }

    setSentCount(state => state + 1);
  };

  return (
    <a href="#" onClick={handleClick} className={linkClassName}>
      {passwordlessState.loading
        ? 'Sending...'
        : `Re-send ${canResendIn > 0 ? ` in ${canResendIn} seconds` : ''} `}
    </a>
  );
};

export default ResendLink;
