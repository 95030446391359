import React from 'react';
import { Form, Formik } from 'formik';
import { BookPageFormProps, PackageFormState } from '../@types';
import FormFooter from '../components/book/form/footer';
import { schema } from './schemas/package';
import { findCarTypeById } from '../runtime/search';
import FormRow from '../components/book/form/row';
import RadioGroupField from './fields/radio-group';
import PackageRadioGroup from '../components/book/form/package-radio-group';

const PackageForm: React.FC<BookPageFormProps> = ({
  id,
  active,
  first,
  last,
  runtimeData,
  formState,
  autoSubmitted,
  onNext,
  onBack,
}) => {
  if (typeof formState.vehicle === 'undefined') {
    return null;
  }

  const initialValues: PackageFormState = {
    package: null,
  };

  const packages = findCarTypeById(formState, runtimeData)?.packages;

  if (typeof packages === 'undefined') {
    return null;
  }

  const onSubmit = (values: PackageFormState) => {
    onNext(id, values);
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <Form>
        <FormRow>
          <RadioGroupField
            name="package"
            items={packages}
            element={PackageRadioGroup}
          />
        </FormRow>
        <FormFooter
          autoSubmitted={autoSubmitted}
          active={active}
          first={first}
          last={last}
          onBack={onBack}
        />
      </Form>
    </Formik>
  );
};

export default PackageForm;
