import React from 'react';
import cntl from 'cntl';

interface FormLayoutProps {
  id: string;
  title: string;
  active: boolean;
}

const disabledClassName = cntl`
  hidden
`;

const titleClassName = cntl`
  font-black
  text-2xl
  lg:text-3xl
  font-serif
`;

const containerClassName = cntl`
  max-w-lg
  mt-6
  mb-12
`;

const FormLayout: React.FC<FormLayoutProps> = ({
  id,
  title,
  active,
  children,
}) => {
  return (
    <div id={id} className={`${!active && disabledClassName}`}>
      <div className={titleClassName}>{title}</div>
      <div className={containerClassName}>{children}</div>
    </div>
  );
};

export default FormLayout;
