import React from 'react';
import cntl from 'cntl';
import {
  BookFormState,
  BookPageRuntimeQuery,
  RuntimeQueryHookState,
} from '../../@types';
import {
  findPackageByCarTypeId,
  findSelectedServices,
} from '../../runtime/search';
import getOrderTotal from '../../runtime/get-order-total';
import { formatMinutes, getTimeSlotDate } from '../../runtime/timeslot-utils';

interface PreviewProps {
  state: BookFormState;
  runtimeData: RuntimeQueryHookState<BookPageRuntimeQuery>;
}

const containerClassName = cntl`
  pt-5
  pb-10
  mb-10
  pl-4
  pr-4
  sm:pl-8
`;

const titleClassName = cntl`
  mt-8
  text-md
  font-bold
  text-gray-700
`;

const valueClassName = cntl`
  mt-1
  text-sm
  text-gray-900
  mb-8
`;

const colorIconClassName = cntl`
  flex-shrink-0
  inline-block
  h-2
  w-2
  mx-1
  rounded-full
`;

const Preview: React.FC<PreviewProps> = ({ state, runtimeData }) => {
  const { address, vehicle, timeSlot } = state;
  const date = getTimeSlotDate(timeSlot?.timeSlot);

  const services = findSelectedServices(state, runtimeData);
  const pkg = findPackageByCarTypeId(state, runtimeData);
  const total = getOrderTotal(state, runtimeData);

  return (
    <div className={containerClassName}>
      <h2 className="text-lg font-medium text-gray-900">Order summary</h2>
      {!address && (
        <p className="mt-4 text-sm text-gray-500">
          Your order details will appear here.
        </p>
      )}
      <dl>
        {address && (
          <div>
            <dt className={titleClassName}>Address</dt>
            <dd className={valueClassName}>
              {address?.address?.formatted_address}
            </dd>
          </div>
        )}
        {vehicle && (
          <div>
            <dt className={titleClassName}>Vehicle</dt>
            <dd className={valueClassName}>
              <div>
                {vehicle?.year?.label} {vehicle?.make?.label}{' '}
                {vehicle?.model?.label} {vehicle?.color?.label}{' '}
                <span
                  aria-label={vehicle?.color?.label}
                  className={colorIconClassName}
                  style={{
                    backgroundColor: vehicle?.color?.value.toLowerCase(),
                  }}
                />
              </div>
            </dd>
          </div>
        )}
        {timeSlot && (
          <div>
            <dt className={titleClassName}>Date and time</dt>
            <dd className={valueClassName}>
              <div>{date.format('dddd, MMMM Do YYYY')}</div>
              <div>{date.format('h:mm a')}</div>
            </dd>
          </div>
        )}
        {pkg && (
          <div>
            <dt className={titleClassName}>Services</dt>
            <dd className={valueClassName}>
              <div className="text-gray-800">
                {pkg?.name} / {pkg?.time} min · ${pkg?.price}
              </div>
              {services.map(service => (
                <div key={service.sys.id} className="mt-2">
                  {service.service.name} / {service?.time} min · $
                  {service.price}
                </div>
              ))}
            </dd>
          </div>
        )}
        {total && (
          <div>
            <dt className={titleClassName}>Total cost and duration</dt>
            <dd className={valueClassName}>
              <div>${total?.totalCost}</div>
              <div>{formatMinutes(total?.timeInMinutes || 0)}</div>
            </dd>
          </div>
        )}
      </dl>
    </div>
  );
};

export default Preview;
