import React, { Fragment } from 'react';
import cntl from 'cntl';
import { Link } from 'gatsby';
import { Moment } from 'moment';
import { Dialog, Transition } from '@headlessui/react';
import {
  CheckIcon,
  InformationCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/outline';
import { classNames } from '../../utils';

interface VerifyDialogProps {
  open: boolean;
  success: boolean;
  error: boolean;
  paymentDeclined: boolean;
  appointmentDate: Moment;
  onClose: () => void;
}

const dialogClassName = cntl`
  fixed
  z-10
  inset-0
  overflow-y-auto
`;

const dialogTitleClassName = cntl`
  text-center
  text-lg
  leading-6
  font-medium
  text-gray-900
`;

const dialogWrapperClassName = cntl`
  flex
  items-end
  justify-center
  min-h-screen
  pt-4
  px-4
  pb-20
  text-center
  sm:block
  sm:p-0
`;

const overlayClassName = cntl`
  fixed
  inset-0
  bg-gray-500
  bg-opacity-75
  transition-opacity
`;

const trickingElementClassName = cntl`
  hidden
  sm:inline-block
  sm:align-middle
  sm:h-screen
`;

const contentContainerClassName = cntl`
  inline-block
  align-bottom
  bg-white
  rounded-lg
  px-4
  pt-5
  pb-4
  text-left
  overflow-hidden
  shadow-xl
  transform
  transition-all
  sm:my-8
  sm:align-middle
  sm:max-w-sm
  sm:w-full
  sm:p-6
`;

const headerClassName = cntl`
  mx-auto
  mb-6
  flex
  items-center
  justify-center
  h-12
  w-12
  rounded-full
`;

const wrapperClassName = cntl`
  mt-4
  sm:mt-5
`;

const iconClassName = cntl`
  h-6
  w-6
`;

const successMessageClassName = cntl`
  text-sm
  text-gray-500
  text-center
`;

const homePageLinkClassName = cntl`
  inline-flex
  justify-center
  w-full
  rounded-md
  border
  border-transparent
  shadow-sm
  px-4
  py-2
  bg-primary-600
  text-base
  font-medium
  text-white
  hover:bg-primary-700
  focus:outline-none
  focus:ring-2
  focus:ring-offset-2
  focus:ring-primary-500
  sm:text-sm
`;

const VerifyDialog: React.FC<VerifyDialogProps> = ({
  children,
  open,
  success,
  error,
  paymentDeclined,
  appointmentDate,
  onClose,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className={dialogClassName}
        open={open}
        onClose={onClose}
      >
        <div className={dialogWrapperClassName}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className={overlayClassName} />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className={trickingElementClassName} aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={contentContainerClassName}>
              <div className={wrapperClassName}>
                <div
                  className={classNames(
                    headerClassName,
                    error || paymentDeclined ? 'bg-red-100' : 'bg-primary-100'
                  )}
                >
                  {error || paymentDeclined ? (
                    <ExclamationCircleIcon
                      className={classNames(iconClassName, 'text-red-600')}
                      aria-hidden="true"
                    />
                  ) : success ? (
                    <CheckIcon
                      className={classNames(iconClassName, 'text-primary-600')}
                      aria-hidden="true"
                    />
                  ) : (
                    <InformationCircleIcon
                      className={classNames(iconClassName, 'text-gray-500')}
                      aria-hidden="true"
                    />
                  )}
                </div>
                {paymentDeclined ? (
                  <>
                    <Dialog.Title as="h3" className={dialogTitleClassName}>
                      Your card was declined
                    </Dialog.Title>
                    <div className="mt-4">
                      <p className={successMessageClassName}>
                        No worries, we still booked your appointment for{' '}
                        {appointmentDate.format('dddd, MMMM Do YYYY h:mm a')}.
                        You’ll just need to pay when the service is complete.
                        See you soon!
                      </p>
                    </div>
                    <div className="mt-6 sm:mt-8">
                      <Link to="/" className={homePageLinkClassName}>
                        Go back to home page
                      </Link>
                    </div>
                  </>
                ) : success ? (
                  <>
                    <Dialog.Title as="h3" className={dialogTitleClassName}>
                      Success!
                    </Dialog.Title>
                    <div className="mt-4">
                      <p className={successMessageClassName}>
                        Your appointment is booked for{' '}
                        {appointmentDate.format('dddd, MMMM Do YYYY h:mm a')}.
                        Check your email for more details. See you soon!
                      </p>
                    </div>
                    <div className="mt-6 sm:mt-8">
                      <Link to="/" className={homePageLinkClassName}>
                        Go back to home page
                      </Link>
                    </div>
                  </>
                ) : (
                  children
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default VerifyDialog;
