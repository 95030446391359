import * as Yup from 'yup';

const selectMenuShape = Yup.object().nullable().shape({
  value: Yup.string(),
  label: Yup.string(),
});

export const schema = Yup.object().shape({
  year: selectMenuShape.required('Year of production is required'),
  make: selectMenuShape.required('Car make is required'),
  model: selectMenuShape.required('Car model is required'),
  color: selectMenuShape.required('Car color is required'),
  carTypeId: selectMenuShape.required('Car type is required'),
});
