import qs from 'querystring';

const calendarID = process.env.GATSBY_G_CALENDAR_ID;
const apiKey = process.env.GATSBY_G_APIS_KEY;

const endpoint = `https://www.googleapis.com/calendar/v3`;
const BASE_URL = `${endpoint}/calendars/${calendarID}/events?key=${apiKey}`;

export default function calendarClient(params: {
  [key: string]: string | boolean;
}): Promise<Response> {
  const stringified = qs.stringify(params);

  return fetch(`${BASE_URL}&${stringified}`);
}
