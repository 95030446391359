import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import { CustomInputProps } from '../../@types';

interface AdditionalProps {
  element: React.ComponentType<
    CustomInputProps & React.HTMLProps<HTMLInputElement>
  >;
}

const TextField: React.FC<
  AdditionalProps & FieldHookConfig<string>
> = props => {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error ? meta.error : undefined;

  const Element = props.element;

  return (
    <Element
      {...field}
      type={props.type}
      placeholder={props.placeholder}
      disabled={props.disabled}
      error={error}
      autoComplete="off"
    />
  );
};

export default TextField;
