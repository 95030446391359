import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/gatsby';
import { Year, Make, Model, RuntimeQueryHookState } from '../../@types';
import carAPIClient from '../client/car-api';
import isRuntime from '../is-runtime';

export default function useCarApi<T>(
  path: string,
  shouldTriggerCommand: boolean
): RuntimeQueryHookState<T> {
  const [state, setState] = useState<RuntimeQueryHookState<T>>({
    loading: false,
    error: false,
    data: null,
  });

  useEffect(() => {
    if (!shouldTriggerCommand || !isRuntime()) {
      return;
    }

    (async () => {
      setState(state => ({ ...state, loading: true }));

      try {
        const response = await carAPIClient(path);

        if (!response.ok) {
          setState(state => ({ ...state, error: true }));
        } else {
          const data = await response.json();
          setState(state => ({ ...state, data }));
        }
      } catch (err) {
        setState(state => ({
          ...state,
          error: true,
        }));
        Sentry.captureException(err);
      } finally {
        setState(state => ({ ...state, loading: false }));
      }
    })();
  }, [path, shouldTriggerCommand]);

  return state;
}

export function useAllYears(): RuntimeQueryHookState<Year[]> {
  const state = useCarApi<Year[]>('car-lists/get/car/years', true);

  return state;
}

export function useAllMakes(
  shouldTriggerCommand: boolean,
  year: string
): RuntimeQueryHookState<Make[]> {
  const state = useCarApi<Make[]>(
    `car-lists/get/car/makes/${year}`,
    shouldTriggerCommand
  );

  return state;
}

export function useAllModels(
  shouldTriggerCommand: boolean,
  year: string,
  make: string
): RuntimeQueryHookState<Model[]> {
  const state = useCarApi<Model[]>(
    `car-lists/get/car/models/${year}/${make}`,
    shouldTriggerCommand
  );

  return state;
}
