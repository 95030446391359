import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/gatsby';
import {
  startPasswordless,
  verifyAuth,
  setupUser,
  createBookingIntent,
  confirmBookingIntent,
} from '../client/api';
import {
  BookFormState,
  BookingIntentResponse,
  BookPageRuntimeQuery,
  RuntimeActionHookState,
  RuntimeQueryHookState,
} from '../../@types';
import isRuntime from '../is-runtime';
import { getTimeSlotDate } from '../timeslot-utils';
import getOrderTotal from '../get-order-total';

interface TokenObject {
  access_token: string;
}

interface VerifyBookHookState {
  state: RuntimeActionHookState<BookingIntentResponse & TokenObject>;
  start: (code: string) => Promise<void>;
}

export function useConfirmIntent(
  access_token: string | undefined,
  bookingId: string | undefined
): void {
  useEffect(() => {
    if (
      !isRuntime() ||
      typeof bookingId === 'undefined' ||
      typeof access_token === 'undefined'
    ) {
      return;
    }

    confirmBookingIntent(access_token, bookingId);
  }, [bookingId]);
}

export function useVerifyAndBook(
  formState: BookFormState,
  runtimeData: RuntimeQueryHookState<BookPageRuntimeQuery>
): VerifyBookHookState {
  const [state, setState] = useState<
    RuntimeActionHookState<BookingIntentResponse & TokenObject>
  >({
    loading: false,
    error: undefined,
    data: null,
  });

  const start = async (code: string) => {
    setState(state => ({ ...state, loading: true }));

    const {
      address,
      vehicle,
      package: pkg,
      services,
      yourInfo,
      timeSlot,
      payment,
    } = formState;

    try {
      // Verify sms code
      const { data } = await verifyAuth(yourInfo?.phone as string, code);

      if (data === null) {
        return;
      }

      const { access_token } = data;

      // Setup user profile information
      // Stripe customer, name etc.
      await setupUser(
        access_token,
        yourInfo?.firstName,
        yourInfo?.lastName,
        yourInfo?.email
      );

      if (typeof payment === 'undefined') {
        return;
      }

      // Create booking intent
      const bookingIntent = await createBookingIntent(access_token, {
        paymentAmount: payment.paymentAmount,
        starts: getTimeSlotDate(timeSlot?.timeSlot).toISOString(),
        address: {
          formatted_address: address?.address?.formatted_address,
          place_id: address?.address?.place_id,
        },
        vehicle: {
          year: vehicle?.year?.value as string,
          make: vehicle?.make?.value as string,
          model: vehicle?.model?.value as string,
          color: vehicle?.color?.value as string,
          carTypeId: vehicle?.carTypeId?.value as string,
        },
        packageId: pkg?.package as string,
        serviceIds: services === undefined ? [] : services.services,
      });

      setState(state => ({
        ...state,
        error: undefined,
        data:
          bookingIntent.data !== null
            ? { ...bookingIntent.data, access_token }
            : null,
      }));

      if (typeof window !== 'undefined') {
        typeof window.dataLayer !== 'undefined' &&
          // Track conversion on Google Tag Manager
          window.dataLayer.push({
            event: 'booking-conversion',
            conversionValue: getOrderTotal(
              formState,
              runtimeData
            )?.totalCost.toString(),
          });
      }
    } catch (err) {
      if (err instanceof Error) {
        const splitMessage = err.message.split('::');

        setState(state => ({
          ...state,
          error:
            splitMessage.length > 1
              ? splitMessage[1]
              : 'Something went wrong. Please re-load the page and try again.',
        }));
      }

      Sentry.captureException(err);
    } finally {
      setState(state => ({ ...state, loading: false }));
    }
  };

  return {
    state,
    start,
  };
}

export function useStartPasswordless(
  phone: string | undefined,
  sentCount: number
): RuntimeQueryHookState<number> {
  const [state, setState] = useState<RuntimeQueryHookState<number>>({
    loading: false,
    error: false,
    data: 0,
  });

  useEffect(() => {
    if (!isRuntime() || sentCount === 0 || typeof phone === 'undefined') {
      return;
    }

    setState(state => ({ ...state, loading: true, error: false }));

    (async () => {
      try {
        await startPasswordless(phone);

        // Track the number of times
        // Code has been sent to phone number
        setState(state => ({
          ...state,
          data: sentCount,
        }));
      } catch (err) {
        setState(state => ({ ...state, error: true }));
        Sentry.captureException(err);
      } finally {
        setState(state => ({ ...state, loading: false }));
      }
    })();
  }, [phone, sentCount]);

  return state;
}
