import React from 'react';
import cntl from 'cntl';
import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';

const cardElementClassName = cntl`
  border
  border-primary-300
  px-3
  py-4
`;

const focusClassName = cntl`
  outline-none
  ring-1
  ring-primary-500
  border-primary-500
`;

const invalidClassName = cntl`
  outline-none
  ring-1
  ring-red-500
  border-red-500
`;

const errorMessageClassName = cntl`
  mt-2
  text-sm
  text-red-600
`;

interface CardInputProps {
  onChange: (c: StripeCardElementChangeEvent) => void;
  error: string | undefined;
}

const CardInput: React.FC<CardInputProps> = ({ error, onChange }) => {
  return (
    <div>
      <div>
        <CardElement
          onChange={onChange}
          options={{
            classes: {
              invalid: invalidClassName,
              focus: focusClassName,
            },
          }}
          className={cardElementClassName}
        />
      </div>
      {error !== undefined ? (
        <p className={errorMessageClassName}>{error}</p>
      ) : null}
    </div>
  );
};

export default CardInput;
