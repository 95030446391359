import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import { CustomRadioGroupProps } from '../../@types';

interface AdditionalProps<T> {
  items: T;
  element: React.ComponentType<CustomRadioGroupProps<T>>;
}

function RadioGroupField<T>(
  props: AdditionalProps<T> & FieldHookConfig<string | null>
): JSX.Element {
  const [, meta, helpers] = useField(props);
  const { value } = meta;
  const { setValue } = helpers;
  const error = meta.touched && meta.error ? meta.error : undefined;
  const Element = props.element;

  return (
    <Element
      items={props.items}
      selected={value}
      setSelected={selected => setValue(selected)}
      error={error}
    />
  );
}

export default RadioGroupField;
